import React, {useEffect} from 'react';
import NavTitle from '@basicComponents/nav-title';
import {View, Image} from 'react-native';
import theme from '@style';
import {goBack, goTo, useResponsiveDimensions} from '@utils';
import {ScrollView} from 'react-native-gesture-handler';
import PhoneInput from './components/phone-input';
import CodeInput from './components/code-input';
import {inputProps, styles} from './login.style';
import {CheckBox, Input} from '@rneui/themed';
import Text from '@basicComponents/text';
import AccountTip from './components/account-tip';
import Button from '@basicComponents/button';
import {userLogin} from './login.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import globalStore from '@/services/global.state';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {setScratchAuth} from '@/services/global.service';
import {useTranslation} from 'react-i18next';
import {defaultPhoneCode} from '@/config';
import {postUserInfo} from '@/services/global.service';
import Privacy from '@components/business/privacy/privacy';
import {setUniqueId} from '@/utils/moengage';
// import LazyImage from '@/components/basic/image';
// const icon = require('../../assets/icons/login/login-botttom.webp');

const SingUp = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();

  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  // const [layoutHeight, setLayoutHeight] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {width: screenWidth, height: screenHeight} = useResponsiveDimensions();

  const [userPhone, setUserPhone] = React.useState('');
  const [OTPCode, setOTPCode] = React.useState('');
  const [invitaCode, setInvitaCode] = React.useState('');
  const [is18, setIs18] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [blured, setBlured] = React.useState(true);
  const [userPhoneCode, setUserPhoneCode] = React.useState(defaultPhoneCode);
  // const handleLayout = ({nativeEvent}: SafeAny) => {
  //   setLayoutHeight(nativeEvent.layout.height);
  // };
  useEffect(() => {
    let invitationCode = localStorage.getItem('invitationCode');
    if (invitationCode === '' || invitationCode === 'undefined') {
      localStorage.removeItem('invitationCode');
      setInvitaCode('');
      return;
    }
    if (globalStore.isWeb) {
      setInvitaCode(invitationCode || '');
    }
  }, []);
  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.white]}>
      <NavTitle onClose={goBack} title={i18n.t('login.label.sign')} />
      <ScrollView>
        <View
          // onLayout={handleLayout}
          style={[
            {
              padding: theme.paddingSize.l * 2,
              // marginBottom:
              //   screenHeight < 740
              //     ? screenHeight - layoutHeight - 220
              //     : screenHeight - layoutHeight - 340,
            },
          ]}>
          <PhoneInput
            userPhoneCode={userPhoneCode}
            setUserPhoneCode={setUserPhoneCode}
            userPhone={userPhone}
            setUserPhone={setUserPhone}
          />
          <View style={styles.interval} />
          <CodeInput
            setValueOrCode={setOTPCode}
            switchIndex={1}
            userPhone={userPhone}
            OTPCode={OTPCode}
          />
          <View style={styles.interval} />
          <View
            style={[
              styles.inputBox,
              blured ? styles.greyBorder : styles.deepBorder,
            ]}>
            <Image
              style={theme.icon.s}
              source={require('@assets/icons/login/invitation-code.webp')}
            />
            <View style={theme.flex.flex1}>
              <Input
                {...inputProps}
                onFocus={() => setBlured(false)}
                onBlur={() => setBlured(true)}
                value={invitaCode}
                onChangeText={setInvitaCode}
                maxLength={8}
                placeholder={i18n.t('login.tip.referral-code')}
              />
            </View>
          </View>
          {[
            {checked: is18, onPress: setIs18, label: 'login.tip.page18'},
            {checked: agree, onPress: setAgree, label: 'login.tip.notify'},
          ].map((v, i) => (
            <CheckBox
              key={i}
              containerStyle={{
                padding: theme.paddingSize.zorro,
                marginTop: theme.paddingSize.m * 2,
                marginLeft: theme.paddingSize.zorro,
                marginRight: theme.paddingSize.zorro,
              }}
              checked={v.checked}
              onPress={() => v.onPress(!v.checked)}
              checkedIcon={
                <Image
                  style={theme.icon.m}
                  source={require('@assets/icons/checked.webp')}
                />
              }
              uncheckedIcon={
                <Image
                  style={theme.icon.m}
                  source={require('@assets/icons/unchecked.webp')}
                />
              }
              title={
                <Text
                  style={theme.padding.lefts}
                  fontSize={theme.fontSize.m}
                  accent>
                  {i18n.t(v.label)}
                </Text>
              }
            />
          ))}
          <View
            style={{
              marginVertical: theme.paddingSize.m * 2,
            }}>
            <Button
              onPress={() => {
                globalStore.globalLoading.next(true);
                userLogin(userPhone, OTPCode, invitaCode, false)
                  .then(async res => {
                    globalStore.token = res.token;
                    const data: BasicObject = {fromLogin: true};
                    sucessPage && (data.sucessPage = sucessPage);
                    sucessPageParams &&
                      (data.sucessPageParams = sucessPageParams);
                    setScratchAuth();
                    goTo('SetPassword', data);
                    if (res.isNewUser) {
                      postReport({
                        reportType: TReportType.REGISTER,
                        inviteCode: invitaCode,
                        sourceType:
                          localStorage.getItem('from') === 'im'
                            ? TSourceType.IM
                            : TSourceType.CHANNEL,
                      });
                    }
                    const userInfo = await postUserInfo();
                    globalStore.userInfo = userInfo;
                    const {userId, packageId} = userInfo;
                    setUniqueId(`${userId}_${packageId}`);
                  })
                  .finally(() => globalStore.globalLoading.next(false));
              }}
              disabled={
                userPhone.length !== 10 ||
                OTPCode.length !== 6 ||
                !is18 ||
                !agree
              }>
              <Text
                color={theme.basicColor.white}
                size="large"
                fontWeight="700">
                {i18n.t('login.label.next')}
              </Text>
            </Button>
          </View>
        </View>
        {/* {globalStore.packageId === 2 && (
          <LazyImage
            occupancy="transparent"
            width={screenWidth}
            height={290}
            imageUrl={icon}
          />
        )} */}
        <AccountTip
          tip="login.tip.has-account"
          linkTip="login.tip.sign-in"
          onPressLink={() => goTo('Login')}
        />
        <View
          style={[
            {
              paddingHorizontal: theme.paddingSize.l * 2,
            },
          ]}>
          <Privacy fix={true} />
        </View>
      </ScrollView>
    </View>
  );
};

export default SingUp;
